<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient :client_id="client_id" @getClient="getClient"/><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="dark-text header-w-bg bottom-20">Adult 65D-30 Outpatient ASAM Level I</div>

                <p>Check all items in each dimension that apply to the client.Place a check in the “yes” or “no” box that indicates validation or lack of validation for placement into this level of care.</p>
                <p>ASAM Requirements: Meets criteria in all six dimensions.</p>

                <div class="avoid-break">
                    <h3>Dimension 1: Acute Intoxication and/or Withdrawal Potential</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_one"
                        label="The client’s status in this dimension is characterized by one of the following:"
                        :options="[
                            'a. Client is free from intoxication or withdrawal symptoms/risks; or',
                            'b. The client’s intoxication or withdrawal symptoms/risks can be managed at this level of care.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 2: Biomedical Conditions and Complications</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_two"
                        label=""
                        :options="[
                            'a. None or very stable - The client’s biomedical conditions, if any, are stable enough to participate in outpatient treatment.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 3: Emotional, Behavioral or Cognitive Conditions and Complications</h3>
                    <!--
                    Note for Nate:
                     - Please create a copy of the radios styling, but for checkboxes.
                     - This applies to both Dimension 3 and Dimension 4
                     - src/components/general/validatedInputs/Checkboxes.vue might also need styling.
                    -->
                    <Checkboxes
                        class="asam-checkboxes"
                        v-model="rec.dimension_three"
                        label="None or very stable – The situation is characterized by all of the following:"
                        :options="[
                            'a. The client’s anxiety, guilt and/or depression, if present, appear to be related to substance- related problems rather than to a coexisting psychiatric/emotional/behavioral or cognitive condition. If they are related to such a condition, appropriate care is being provided concurrent with ASAM Level I; and',
                            'b. The client’s mental status does not preclude his/her ability to understand the materials presented or to participate in the treatment process; and',
                            'c. The client is assessed as not posing a risk of harm to self or others.']"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dual Diagnosed Enhanced Programs</h3>
                    <Checkboxes
                        class="asam-checkboxes"
                        v-model="rec.dimension_three_a"
                        label="In addition to the foregoing criteria, either (a) or (b) and (c) and (d) characterize the client’s status in Dimension 3."
                        :options="[
                            'a. The client has a severe and persistent mental illness that impairs his or her ability to follow through consistently with mental health appointments and psychotropic medication. The client maintains the ability to access services such as assertive community treatment and intensive case management or supportive living designed to help the client remain engaged in treatment; or',
                            'b. The client has a severe and persistent mental disorder or other emotional, behavioral or cognitive problems, or substance-induced disorder; and',
                            'c. The client’s mental health functioning is such that he or she has impaired ability to: 1) understand the information presented, and 2) participate in treatment planning and the treatment process. Mental health management is required to stabilize mood, cognition and behavior; and',
                            'd. The client is assessed as not posing a risk of harm to self or others and is not vulnerable to victimization by another.']"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 4: Readiness to Change</h3>
                    <Checkboxes
                        class="asam-checkboxes"
                        v-model="rec.dimension_four"
                        label="Willing to cooperate but needs motivating and monitoring strategies – The situation is characterized by the following:"
                        :options="[
                            'a. The client wants to adhere to the treatment plan and attend all scheduled activities; and',
                            'b. The client admits to a substance abuse and/or a mental health problem but requires monitoring and motivating strategies. A structured residential program is not required; or',
                            'c. The client is ambivalent about or does not recognize that he or she has a substance-related and/or mental health problem; or',
                            'd. The client may not recognize that they have a substance-related and/or mental health problem. They may require monitoring and motivating strategies to engage in treatment and to progress through the stages of change.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 5: Relapse/Continued Use Potential</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_five"
                        label=""
                        :options="[
                            'Able to maintain abstinence and pursue recovery goals with minimal support – The client is assessed as being able to achieve or maintain abstinence and recovery goals only with support and scheduled counseling to assist in dealing with issues that include mental preoccupation with alcohol or other drugs, craving, peer pressure, lifestyle, attitude changes and other treatment plans issues.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dual Diagnosed Enhanced Programs</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_five_a"
                        label=""
                        :options="[
                            'In addition to the criteria listed above, the client is assessed as able to achieve or maintain mental health functioning and related goals only with support and scheduled therapeutic contact to assist him or her in dealing with issues that include (but are not limited to) impulses to harm self or others and difficulty in coping with his or her affects, impulses or cognition.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dimension 6: Recovery Environment</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_six"
                        label="The situation is characterized by one of the following:"
                        :options="[
                            'a. A sufficiently supportive psychosocial environment makes outpatient treatment feasible; or',
                            'b. Although the client does not have an ideal primary or social support system to assist with sobriety, he or she has demonstrated motivation and willingness to obtain such a support system; or',
                            'c. Family and significant others are supportive but require professional interventions to improve the client’s chance of treatment success and recovery.'
                        ]"
                    />
                </div>
                <div class="avoid-break">
                    <h3>Dual Diagnosed Enhanced Programs</h3>
                    <Radios
                        class="block radios asam-radios"
                        v-model="rec.dimension_six_a"
                        label="In addition to the criteria listed above, (a) or (b) or (c) characterizes the client’s status in Dimension 6."
                        :options="[
                            'a. The client does not have an adequate primary or social support system and has mild impairment in his or her ability to obtain a support system; or',
                            'b. The family guardian or significant others require active family therapy or systems interventions to improve the client’s chances of treatment success and recovery; or',
                            'c. The client’s status in Dimension 6 is characterized by all of the following: 1) the client has a severe and persistent mental disorder or emotional, behavioral or cognitive problem, and 2) the client does not have an adequate family or social support system, and 3) the client is chronically impaired, however, not in imminent danger, and limited ability to establish a supportive recovery environment. (The client however, does not have access to intensive outreach and case management services that can provide structure and allow him or her to work toward stabilizing both the substance and mental health related disorders). The client does however, have access to intensive outreach and case management services that can provide structure and allow them to work toward stabilizing both the substance related and mental disorders'
                        ]"
                    />
                </div>
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="recommendations"
                    v-model="rec.recommendations"
                    label="Recommendations/Notes:"
                    :limit=4000
                    inputStyle="min-height:20px;"
                />

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />            
            </VIfWorkableModal>
        </div>
    </div>
</template>

<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';

    export default {
        name: 'OpAdmissonASAM',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
            };
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
